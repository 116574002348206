const url = "https://connect.accunix.net";
export const FbCheckLoginStatus = () => {
  return fetch(url + "/FBAD/check_status.php?orgID=9&uid=9&debug=Y").then((res) => {
    if (res.status !== 200) throw new Error();
    return res.json();
  });
};
export const FbGetAdAccount = () => {
  return fetch(url + "/FBAD/get_ADList.php?orgID=9&uid=9").then((res) => {
    if (res.status !== 200) throw new Error();
    return res.json();
  });
};
export const FbGetAdGroup = (ADID) => {
  return fetch(url + `/FBAD/get_CSList.php?orgID=9&uid=9&ADID=${ADID}`).then(
    (res) => {
      if (res.status !== 200) throw new Error();
      return res.json();
    }
  );
};
export const FbCreateNewGroup = ({ orgID, uid, ADID, can, cad }) => {
  return fetch(url + "/FBAD/add_CSList.php", {
    method: "post",
    body: JSON.stringify({
      orgID,
      uid,
      ADID,
      can,
      cad,
    }),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((res) => {
    if (res.status !== 200) throw new Error();
    return res.json();
  });
};
export const FbAdSend = ({ aid }) => {
  return fetch(url + "/FBAD/add_CSData.php", {
    method: "post",
    body: JSON.stringify({
      orgID: "9",
      uid: "9",
      aid,
      fpc: [
        "000f02aef06e9a28e7135ad7359b0f98-_K3NNAQP3MPZGQ",
        "0010f6c3905d888e4a73f9ad6e1198ba-_KXD3YFHEKLWC7",
        "0010f6c3905d888e4a73f9ad6e1198ba-_KXD3YFHEKLWC7",
        "0010f6c3905d888e4a73f9ad6e1198ba-_KXD3YFHEKLWC7",
        "005f9f8561e05d2da295e8798bbc233e-_KSW181QEIN7Y1",
        "005fbd820058d292f051c025340384de-_KVYSZUNVY9W8B",
        "006b256f078ab99827a53362c9225429-_KW8G09Y4E449G",
        "008ac10d2da17d7aad7bfc6d6182efd7-_KVYNSFKN7SRHV",
        "008e4ad31ebc3e9ea05f2c66c6d2d9be-_KVYK2O493HH8L",
        "009f5bab9a505b88b63e3fd0dc034f4a-_KVYQZPWFK536L",
      ],
    }),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((res) => {
    if (res.status !== 200) throw new Error();
    return res.json();
  });
};

const whatsAppKeys = {
  APP_ID: 2033746636768586,
  APP_SECRET: 'a8334a07531370c9d6e7a38726de2ce3',
  SYSTEM_USER_ACCESS_TOKEN: 'EAAc5ronZABUoBO2GZAsfBnPb0VP2qZBGa5C5GDFE40SdAIdGEzEWEnN1OyVjDaI937g52RM2SrP5SX63FDoNtGFtWsHIHPWGkXsl3wzrgxaLSB5sXWnrAaMhOXwm9E9bpbS6ouWxLglWO2ofbFXplRmvtiZBSdHwUwxdT8hKMdMXN7co7MOzkXYX1RqlQQzm9ZCL4N39HAbWPiL7E',
  BUSINESS_MANAGER_ACCOUNT_ID: 2085403311713853
}
export const WhatsAppGetAccessToken = ({ code }) => {
  return fetch(
    `https://graph.facebook.com/v17.0/oauth/access_token?client_id=${whatsAppKeys.APP_ID}&client_secret=${whatsAppKeys.APP_SECRET}&code=${code}
  `)
  .then((res) => {
    if (res.status !== 200) throw new Error();
    return res.json();
  });
};
export const WhatsAppGetWabaUser = ({ token }) => {
  return fetch(
    `https://graph.facebook.com/v19.0/debug_token?input_token=${token}`,
    {
      headers: {
        'Authorization': `Bearer ${whatsAppKeys.SYSTEM_USER_ACCESS_TOKEN}`
      }
    }
  )
  .then((res) => {
    if (res.status !== 200) throw new Error();
    return res.json();
  });
};
export const WhatsAppGetWabaList = () => {
  return fetch(
    `https://graph.facebook.com/v19.0/${whatsAppKeys.BUSINESS_MANAGER_ACCOUNT_ID}/client_whatsapp_business_accounts`,
    {
      headers: {
        'Authorization': `Bearer ${whatsAppKeys.SYSTEM_USER_ACCESS_TOKEN}`
      }
    }
  )
  .then((res) => {
    if (res.status !== 200) throw new Error();
    return res.json();
  });
};
export const WhatsAppGetTemplateList = ({ wabaId }) => {
  return fetch(
    `https://graph.facebook.com/v19.0/${wabaId}/message_templates?limit=10`,
    {
      headers: {
        'Authorization': `Bearer ${whatsAppKeys.SYSTEM_USER_ACCESS_TOKEN}`
      }
    }
  )
  .then((res) => {
    if (res.status !== 200) throw new Error();
    return res.json();
  });
};
export const WhatsAppGetPhoneNumbers = ({ wabaId }) => {
  return fetch(
    `https://graph.facebook.com/v19.0/${wabaId}/phone_numbers?access_token=${whatsAppKeys.SYSTEM_USER_ACCESS_TOKEN}`
  )
  .then((res) => {
    if (res.status !== 200) throw new Error();
    return res.json();
  });
};
export const WhatsAppSendTemplate = ({ phoneNumberId, toPhoneNumber, templateName, language }) => {
  return fetch(
    `https://graph.facebook.com/v19.0/${phoneNumberId}/messages`,
    {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${whatsAppKeys.SYSTEM_USER_ACCESS_TOKEN}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: toPhoneNumber,
          type: "template",
          template: {
            name: templateName,
            language: {
              code: language
            },
            components: [
              {
                type: "body",
                parameters: [
                  {
                    type: "text",
                    text: "小張"
                  }
                ]
              }
            ]
          }
        }
      )
    }
  )
  .then((res) => {
    if (res.status !== 200) throw new Error();
    return res.json();
  });
};
